import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdUnitsOutlined as SpainReferralIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import { AccountCurrentAgent } from '@klr/api-connectors'
import { useFetchBranchList } from '@klr/system-entities'

import { SPAIN_COUNTRY_CODE } from '../../auth.constants'
import { useAuth } from '../../context/hooks/useAuth'

import { SpainInviteLinkModal } from './components/SpainInviteLinkModal'

interface SpainInviteLinkModalButtonProps {
  spainMarketplaceURL: string
}

export const SpainInviteLinkModalButton = ({
  spainMarketplaceURL,
}: SpainInviteLinkModalButtonProps) => {
  const { t } = useTranslation()
  const { user, isAgent } = useAuth()
  const [open, setOpen] = useState(false)

  const { data: branchList } = useFetchBranchList(
    // we query the branches only if the user is
    // an agent and has an invitation link, also belongs to a branch
    {
      enabled: isAgent && !!user?.invite && !!(user as AccountCurrentAgent).branch_id,
    }
  )

  const branchItem = useMemo(() => {
    return branchList?.find((item) => item.id === (user as AccountCurrentAgent).branch_id) || null
  }, [branchList, user])

  // we render an icon for getting the spanish invite link only if
  // an agent belongs to the spanish branch
  if (!branchItem || branchItem.country.code !== SPAIN_COUNTRY_CODE) return null

  const inviteLink = user?.invite ? `${spainMarketplaceURL}?ref=${user.invite}` : ''

  return (
    <>
      <Tooltip title={t('Auth.spain_invite_link')}>
        <IconButton onClick={() => setOpen(true)} data-testid="spain-invite-link">
          <SpainReferralIcon />
        </IconButton>
      </Tooltip>
      <SpainInviteLinkModal open={open} onClose={() => setOpen(false)} inviteLink={inviteLink} />
    </>
  )
}
