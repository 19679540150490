import { AxiosPromise } from 'axios'

import axios from '../../_axios/axios'
import { LoyaltyLevelDTO } from '../../_shared'
import { DetailDataType, ListDataPaginationType, ListDataType } from '../../_types'
import {
  CustomerDTO,
  CustomerLoyaltyItem,
  CustomerLoyaltyReqFetchList,
  CustomerLoyaltyTransaction,
} from '../models'

export const apiFetchCustomerLoyaltyLevels = (): AxiosPromise<ListDataType<LoyaltyLevelDTO>> => {
  return axios.get('/customers/loyalty/levels')
}

export const apiCustomerFetchLoyalty = (
  id: CustomerDTO['id']
): AxiosPromise<DetailDataType<CustomerLoyaltyItem>> => {
  return axios.get(`/customers/${id}/loyalty`)
}

export const apiCustomerFetchTransactions = ({
  id,
  params,
}: CustomerLoyaltyReqFetchList): AxiosPromise<
  ListDataPaginationType<CustomerLoyaltyTransaction>
> => {
  return axios.get(`/customers/${id}/transactions`, { params })
}
